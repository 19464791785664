import * as React from "react";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/socks";
import { associates } from "../content/pages/associatesSecondary";

// components
import Layout from "../components/layout";
import CenteredPage from "../components/_page/centeredPage";

class socksPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.content = pageTexts(this.t);
    this.associates = associates(this.t);
  }

  render() {
    return (
      <Layout pageName="socks" whiteMenu={false}>
        <CenteredPage content={this.content} associates={this.associates}></CenteredPage>
      </Layout>
    );
  }
}

export default withTrans(socksPage);
